/*****************************
  banner
*****************************/

.banner{
	padding: 350px 0px;
	h1{
		font-size: 72px;
		font-weight: 700px;
	}
	.lead{
		font-size: 24px;
		font-weight: 500;
	}
}

/* Banner 02 */

.slider-01 .swiper-slide{
	height: 800px;
	h1{
		font-size: 60px;
		margin-bottom: 20px;
	}
	h4{
		margin-bottom: 25px;
	}
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev{
    height: 85px;
	width: 40px;
	right: 70px;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next{
    height: 85px;
	width: 40px;
	left: 70px;
}

.swiper-button-prev {
	font-size: 40px;
	line-height: 40px;
	color: $gray-8;
	display: inline-block;
	i{
		position: absolute;
		content: "";
		height: 85px;
		width: 40px;
		top: 0;
		z-index: -1;
		background-color:rgba($white,0.4);
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		transition: all 0.5s ease-in-out;
		&:before{
			position: absolute;
		    top: 27%;
		    left: 15px;
		}
	}
}

.swiper-button-next {
	font-size: 40px;
	line-height: 40px;
	color: $gray-8;
	display: inline-block;
	i{
		position: absolute;
		content: "";
		height: 85px;
		width: 40px;
		top: 0;
		z-index: -1;
		background-color:rgba($white,0.4);
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		transition: all 0.5s ease-in-out;
		&:before{
			position: absolute;
			top: 27%;
			right: 15px;
		}
	}
}

.swiper-button-prev i:hover,
.swiper-button-next i:hover{
	background:rgba($primary,0.4);
}

.swiper-button-prev:focus,
.swiper-button-next:focus{
	outline: none;
}

/* Banner Overlay */

.slider-01{
	position: relative;
}

.banner-overlay-left{
	position: relative;
	z-index: 9;
	text-align: left;
	padding: 150px 0px;
	h1{
		margin-left: 70px;
	}
	.btn{
		margin-left: 70px;
	}
	&:before{
		position: absolute;
		content: "";
		background:rgba($gray-9,0.7);
		height: 100%;
	    width: 20%;
	    border-radius: $border-radius;
	    top: 0px;
		z-index: -9;
		left: 0%;
	}
}

.banner-overlay-right{
	position: relative;
	z-index: 9;
	text-align: right;
	padding: 150px 0px;
	h1{
		margin-right: 70px;
	}
	.btn{
		margin-right: 70px;
	}
	&:before{
		position: absolute;
		content: "";
		background:rgba($gray-9,0.7);
		height: 100%;
		width: 20%;
		border-radius: $border-radius;
		top: 0;
		z-index: -9;
		right: 0%;
	}
}

.swiper-button-next,
.swiper-button-prev{
	background-image: inherit !important;
	z-index: 1;
}

.banner-shap-right{
    position: absolute;
    content: "";
	background:rgba($primary,0.5);
    height: 40%;
    width: 60%;
    top: 77%;
    border-radius: $border-radius;
}

.banner-shap-left{
    position: absolute;
    content: "";
	background:rgba($primary,0.5);
    height: 40%;
    width: 60%;
    top: 77%;
    border-radius: $border-radius;
}

/* banner-03 */

.banner-03 {
	position: relative;
	z-index: 0;

	#banner-frame-animation {
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		position: absolute;
	}

	canvas {
		display: block;
	}

	.banner-frame-content{
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
		width: 100%;

		h1{
			font-size: 120px;
			line-height: 100px;
			margin-top:0;
			margin-bottom: 120px;
		}
	}

	.banner-frame > * {
		position: absolute;
	}

	.banner-frame {
		position: relative;
		text-align: left;
		z-index: 9;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 3rem;
		pointer-events: none;
		grid-template-columns: 75% 25%;
	}
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.2,0.3,0.5);
	}
}

/* Banner Shape */

.banner-shape {
	padding: 200px 0;
	margin-bottom: 100px;
	h1{ font-size: 56px; }

}
.banner-shape svg {
 position: absolute;
    bottom: -20px;
    z-index: 1;
    width: 100%;
    height: 124px;
}
.banner-form {
	position: absolute;
	top: -30px;
	z-index: 2;
	left: 15px;
	right: 15px;
	border-radius: $border-radius;
	box-shadow: 0 6px 23px rgba(0,0,0,.1)
}
