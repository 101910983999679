/*****************************
  Helper Classes
*****************************/

.text-primary {
  color: $primary !important;
}

.text-white {
  color: $white;
}

.text-dark {
  color: $gray-8 !important;
}

.text-light {
  color: $gray-3 !important;
}

.text-muted {
  color: #999999 !important;
}

/* background */

.bg-primary {
  background: $primary !important;
}

.bg-dark {
  background: $gray-9 !important;
}

.bg-gray {
  background: $gray-4 !important;
}

/* Page section margin padding */

.space-ptb {
  padding: 100px 0;
}

.space-pt {
  padding-top: 100px ;
}

.space-pb {
  padding-bottom:100px;
}

.space-sm-ptb {
  padding: 70px 0;
}

.space-sm-pt {
  padding-top: 70px;
}

.space-sm-pb {
 padding-bottom:70px;
}

.space-lg-mt{
  margin-top: 150px;
}

.h-100vh {
  height: 100vh !important;
}

/* pre-loader */

#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999; }

#pre-loader img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto; }

/* img holder */

.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

/* Background overlay */

.bg-overlay-black-10 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-20 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-30 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-40 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-50 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-60 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-70 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-80 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-black-90 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-10 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-20 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-30 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-40 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-50 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-60 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-70 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-80 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-white-90 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($white, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-theme-20 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($primary, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-theme-50 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($primary, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-theme-70 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($primary, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-theme-90 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($primary, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

/* avatar */

.avatar {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.avatar.avatar-sm {
  width: 20px;
  height: 20px;
}

.avatar.avatar-md {
  width: 80px;
  height: 80px;
}

.avatar.avatar-lg {
  width: 145px;
  height: 145px;
}

/* font size */
.font-sm {
  font-size: 13px;
}

.font-md {
  font-size: 15px;
}

.font-lg {
  font-size: 17px;
}

.font-xl {
  font-size: 21px;
}

.font-xll {
  font-size: 30px;
}

.font-xlll {
  font-size: 40px;
  line-height: 40px;
}

/* border-radius */

.b-radius{
  border-radius:$border-radius;
}

.b-radius-none {
  border-radius: 0 !important;
}

.b-radius-left-none {
  border-radius: 0 3px 3px 0;
}

.b-radius-right-none {
  border-radius: 3px 0 0 3px;
}

/* z index */

.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

.z-index-999 {
  z-index: 999;
}
