/*****************************
	Portfolio
*****************************/

.my-shuffle-container{
    margin-left: -15px;
    margin-right: -15px;
    .grid-item {
		width: 33.33%;
		padding: 15px;
	}
}

.portfolio-item{
	position: relative;
	.portfolio-overlay {
	    padding: 20px;
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    opacity: 0;
	    align-items: flex-end;
	    transition: all 0.5s ease;
	    .portfolio-description{
    	    position: absolute;
    	    width: 120px;
		    height: 105px;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    &:before{
		    		position: absolute;
		    		content: "";
		    		width: 50%;
		    		height: 100%;
		    		background-color: $primary;
		    		border-radius: $border-radius;
					box-shadow: $box-shadow;
					transition: all 0.5s ease;
		    	}
		    i{
	    		position: absolute;
	    		top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
			    z-index: 9;
			    font-size: 60px;
				color: $gray-8;
				transition: all 0.5s ease;
		    }
		}
	}
	&:hover{
		.portfolio-overlay{
			opacity: 1;
			background: rgba($white,0.5);
		}
	}
}

.filters-group{
	display: table;
    margin: 0 auto 50px;
    text-align: center;
	button{
		margin: 0 15px 10px;
	    padding: 14px 25px;
	    cursor: pointer;
	    font-size: 18px;
	    line-height: 22px;
	    font-weight: 500;
	    background-color:  $gray-9;
	    color: $white;
		border: 1px solid rgba( $gray-9,1);
		border-radius: $border-radius;
	    transition: all 0.3s ease;
	    &:hover{
	    	background-color: $primary;
	    	color: $gray-8;
			border: 1px solid rgba( $primary,1);
	    }
	}
	button.active{
		background-color: $primary;
	    color: $gray-8;
		border: 1px solid rgba( $primary,1);
	}
}

