/*

Template: Sitters - Baby Sitter, Senior Care and Pets Sitter HTML5 Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/

/*================================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
  	- Accordion
  	- Action Box
  	- Blockquote
  	- Button
    - Category
    - Countdown
    - Counter
    - Datetimepicker
    - Feature Info
    - List Style
    - Owl Carousel
    - Portfolio
    - Pricing Table
    - Range Slider
    - Select Dropdown
    - Service
    - Tabs
    - Team
    - Testimonial
 :: Header
   - Header 02
   - Header 03
 :: Banner
   - Banner 02
   - Banner Overlay
   - Banner Shape
 :: Layout
 :: Sidebar
 :: Blog
   - Blog Detail
   - Blog Sidebar
 :: Shop
   - Product
   - Product Detail
   - Checkout
   - Cart
 :: Not Found
 :: Footer
 :: Responsive

======================================
[ End table content ]
======================================*/

// Core files
@import "variables";
@import "typography";
@import "helpers";

// Shortcodes
@import "shortcodes/button";
@import "shortcodes/accordion";
@import "shortcodes/list";
@import "shortcodes/category";
@import "shortcodes/action-box";
@import "shortcodes/feature-info";
@import "shortcodes/service";
@import "shortcodes/portfolio";
@import "shortcodes/team";
@import "shortcodes/testimonial";
@import "shortcodes/accordion";
@import "shortcodes/counter";
@import "shortcodes/tabs";
@import "shortcodes/owl-carousel";
@import "shortcodes/datetimepicker";
@import "shortcodes/pricing";
@import "shortcodes/countdown";
@import "shortcodes/range-slider";
@import "shortcodes/select";
@import "shortcodes/blockquote";

// Structure
@import "header";
@import "sidebar";
@import "banner";
@import "layout";

// Pages
@import "blog";
@import "shop";
@import "error";
@import "footer";

// Responsive
@import "responsive";
