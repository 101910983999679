/*************************
       Responsive
*************************/

/*************************
       1700px
*************************/
@media (min-width: 1200px){

	/* container */
	.container {
	    max-width: 1170px;
	}

}


@media (max-width:1400px) {

	.space-px {
		padding-left: 80px;
		padding-right: 80px;
	}

	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev{
	    	right: 25px;
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next{
	    	left: 25px;
	}

	.header.header-03 .navbar .navbar-nav .nav-item .nav-link{
		padding: 10px 15px;
	}

}

@media (max-width:1199px) {

/* Page section margin padding */
.space-ptb {
  padding: 70px 0;
}

.space-pt {
  padding-top: 70px;
}

.space-pb {
  padding-bottom:70px;
}

.space-sm-ptb {
  padding: 50px 0;
}

.space-sm-pt {
  padding-top: 50px;
}

.space-sm-pb {
  padding-bottom:50px;
}

.space-lg-mt{
  margin-top: 100px;
}

.header .container-fluid{
	padding: 0px 30px;
}
.header.default .navbar .navbar-nav .nav-link {
	padding: 10px 20px;
}


/* pricing-table */
	.pricing-table-style-03 .pricing-table-content .pricing-list-item {
		-webkit-box-flex: 0;
    	flex: 0 0 40%;
	}
	.pricing-table-button a{
		padding: 10px 25px;
	}
	.pricing-table-content {
	    margin-right: 10px !important;
	    margin-left: 10px !important;
	}
	.pricing-prize h2 {
   		font-size: 62px;
	}
	.pricing-table-title{
		flex: 0 0 18%;
	}
	.pricing-table-style-02 .pricing-table p {
	    padding: 0px 15px;
	}
	.pricing-table-style-03 .pricing-table-content .pricing-list-item{
		margin-right: 20px;
	}

	/* blog */
	.blog-post .blog-post-content {
	    text-align: center;
	    padding: 25px 10px 40px;
	    margin: -45px 15px 0;
    }

    .blog-detail .blog-post-navigation .post-navigation .nav-link .nav-previous a {
	    padding-left: 70px;
	}

    .blog-detail .blog-post-navigation .post-navigation .nav-link .nav-next a {
	    padding-right: 70px;
	}

  	/* callout */
  	.callout {
  	  	padding: 50px 0px 50px 50px;
    }

  	/* service-box */
	.service-style-02 {
		padding: 30px 30px 30px 0px;
	}

	.service-style-02 .service-content{
		margin-left:30px;
	}

	.space-px {
		padding-left: 40px !important;
		padding-right: 40px !important;
	}



	/* nav-tabs */
	.nav-tabs .nav-item .nav-link{
	    font-size: 20px;
		padding: 15px 20px;
	}

	/* home-2 */
	.feature-section .btn{
		margin-top: 80px;
	}

	.team-section-title {
	    padding-top: 100px;
	}

	.header.header-02 .navbar-nav .nav-link{
		padding: 20px 20px;
	}


	/* banner-02 */
	.banner-overlay-left,.banner-overlay-right{
		padding: 100px 0px;
	}

	.slider-01 .swiper-slide h1{
		font-size: 50px;
	}

	.banner-overlay-left:before,
	.banner-overlay-right:before{
		width: 17%;
	}

	.slider-01 .swiper-slide{
		height: 700px;
	}

    /* home-03 */
    .sarvice-info-box {
        padding-top: 300px;
    }

    .category-section {
        padding: 50px 35px 0 0;
    }

	/* service-detail */
    .service-add{
       	padding: 40px;
    }

    /* Team */
	.team .team-detail {
		padding: 40px 20px 20px;
	}

	.team .team-detail .team-social ul li a{
		margin-right: 10px;
	}
	.team-single .team-single-detail .list-unstyled {
	    padding: 40px 25px 0px 25px;
	}
	.team-single .contact-form-01 {
	    padding: 40px 25px;
	}

    /* banner-03 */
    .header.header-03 .navbar .navbar-nav .nav-item .nav-link{
        font-size: 14px;
        padding: 10px 13px;
    }

	.header.header-03 .navbar .add-listing{
        flex: 0 0 355px;
        font-size: 14px;
	}

	.header.header-03 .navbar .add-listing .phone{
		font-size: 14px;
	}

	.header.header-03 .navbar .navbar-brand{
		flex: 0 0 160px;
	}
	.header.header-03 .navbar .navbar-nav .nav-item.active .nav-link:before,
	.header.header-03 .navbar .navbar-nav .nav-item .nav-link:before{
		right: calc(100% - 10px);
	}

	.frame-content h1 {
		font-size: 80px;
		line-height: 80px;
	}

	.header-inner {
	    margin-bottom: 40px;
	}

	.header-inner .inner-banner-title{
		font-size: 30px;
	}

	  /* home-05 */
	  .banner-shape{
	  	margin-bottom: 30px;
	  }
	  .banner-shape .banner-form h3{
       font-size: 24px;
	  }

}

@media (max-width:991px) {

	.space-ptb {
		padding: 60px 0;
	}

	.space-pt {
		padding-top: 60px;
	}

	.space-pb {
		padding-bottom: 60px;
	}

	.space-lg-mt {
    	margin-top:0px;
	}

	/* Header */
	.header .add-listing {
	    margin-right: 40px;
	}

	.navbar-brand .logo{
	   	display: none;
	}

	.navbar-brand .logo-sticky{
	  	display: inline-block;
	}

	.header.default .navbar{
		position: relative;
		background: $white;
	}

	.header.default .is-sticky.navbar{
		position: fixed;
	}

	.navbar-toggler {
	    border: none;
	    position: absolute;
	    height: 75px;
	    right: 10px;
	}

	.header .navbar-collapse {
	    position: absolute;
	    top: 100%;
	    z-index: 999;
	    background: $white;
	    width: 100%;
	    max-height: 350px;
	    overflow: auto;
	    left: 0;
	    margin-left: 0px;
	    border-top: 1px solid #eeeeee;
	    border-bottom: 1px solid #eeeeee;
	    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	}
	.navbar .navbar-nav .dropdown-menu li.active > a{
		padding-left: 25px;
	}

	.header.default .navbar,
	.header.header-03 .navbar,
	.header .navbar {
		padding: 0px 15px;
	}

	.navbar .navbar-nav .nav-link {
	    color: $gray-8;
	    padding: 10px 20px;
	}

	.header.default .navbar .navbar-nav .nav-link{
		color: $gray-8;
	}
	.header.default .navbar .navbar-nav .nav-item.active .nav-link,
	.header.default .navbar .navbar-nav .nav-item .nav-link:hover{
		color: $gray-8;
		padding-left: 45px;
	}

	.header.default .navbar .navbar-nav .nav-item.active .nav-link {
	    color: $gray-8;
	}
	.header.default .navbar .navbar-nav .nav-item .nav-link:hover:before,
	.header.default .navbar .navbar-nav .nav-item.active .nav-link:before {
	    right: calc(100% - 35px);
	}

	.header .navbar .dropdown-menu {
	    margin: 0px;
	    font-size: 14px;
	    border-radius: 0px;
	    border: none;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	    background: #f6f6f6;
	    padding: 14px 30px;
	    font-size: 13px;
	}

	.navbar .navbar-nav .nav-link i {
	    position: absolute;
	    right: 20px;
	}

	.header.header-02 .topbar{
	    display: none;
	}

	.header.header-02 .navbar .navbar-brand,
	.header.header-02 .navbar .add-listing {
	   display: block;
	}

	.header.header-02 .navbar .add-listing{
		flex: 0 0 250px;
	}

	.header-inner {
	    margin-bottom: 35px;
	}
	.header-inner .inner-banner-title{
		font-size: 24px;
	}
	.header.header-03 .navbar .navbar-nav .nav-item .nav-link{
		padding:10px 20px;
	}

	.header.header-02 .navbar .navbar-nav .nav-item.active .nav-link,
	.header.header-02 .navbar .navbar-nav .nav-item .nav-link:hover{
		padding-left: 45px;
	}

	.header.header-02 .navbar .navbar-nav .nav-item .nav-link:hover:before,
	.header.header-02 .navbar .navbar-nav .nav-item.active .nav-link:before {
	    right: calc(100% - 35px);
	}

	.header.header-03 .navbar .navbar-nav .nav-item .nav-link {
	    font-size: 16px;
	    padding:10px 20px;
	}
	.header.header-03 .navbar .navbar-nav .nav-item.active .nav-link,
	.header.header-03 .navbar .navbar-nav .nav-item .nav-link:hover{
		padding-left: 45px;
	}

	.header.header-03 .navbar .navbar-nav .nav-item .nav-link:hover:before,
	.header.header-03 .navbar .navbar-nav .nav-item.active .nav-link:before {
	    right: calc(100% - 35px);
	}


	/* section-title */
	.section-title {
	    margin-bottom: 40px;
	}

	.section-title .title {
	    margin-bottom: 20px;
	}

	.section-title h6 {
	    margin-bottom: 20px;
	}

	/* banner */
	.banner {
	    padding: 150px 0px;
	}

	.banner h1 {
	    font-size: 48px;
	}

	/* home */
	.bg-overlay-left:before,
	.bg-overlay-right:before{
		display: none;
	}

	.bg-overlay-right img,
	.bg-overlay-left img{
		margin-bottom: 50px;
	}

	.bg-overlay-left .author-signature img{
		margin-bottom: 0;
	}

	.bg-overlay-left .section-contant {
	    padding-left: 0;
	}

	.callout {
    	padding: 30px 0px 30px 30px;
	}

    .callout .callout-botton .btn{
		padding: 25px 20px;
	}

	/* tab */
    .tab-content #choose-us {
	    margin-top: 50px;
	}
	.tab-content #question {
	    margin-top: 35px;
	}

	.pricing-section-title {
	    padding-top: 60px;
	    padding-bottom: 150px;
	}

	.owl-carousel.testimonial-center .owl-nav .owl-prev {
	    left: 20px;
	}
	.owl-carousel.testimonial-center .owl-nav .owl-next {
	    right: 20px;
	}

	/* home-2 */
	.feature-section .btn{
		margin-top: 60px;
	}

	.team-section-title {
	    padding-top: 80px;
	    padding-bottom: 150px;
	}

	.counter-section {
    	padding: 0px;
	}

	.counter-section:before{
		display: none;
	}

	.owl-carousel .owl-dots{
		margin-top: 0;
	}

	/* footer */
	.footer.footer-01 {
	    padding-top: 130px;
	}
  	.footer .footer-bottom {
		margin-top: 20px;
	}


	/* testimonial */

	.testimonial {
    padding: 70px 60px 30px;
    }
	.testimonial .testimonial-info{
		padding: 140px 40px 75px;
	}

	.testimonial .testimonial-info .testimonial-content{
		font-size: 20px;
	}

	.owl-carousel .owl-nav .owl-prev{
		left: 0;
	}

	.owl-carousel .owl-nav .owl-next{
		right: 0;
	}

	.testimonial-04 {
    	padding: 70px 0px 80px 0;
	}

	/* pricing */
	.pricing-table-content{
	    flex: 0 0 35%;
	}

	.pricing-table-style-03 .pricing-table-content .pricing-list-item {
    	flex: 0 0 100%;
	}

	.pricing-table-button {
    	flex: 0 0 23%;
	}

	.pricing-table-style-03{
		display: block;text-align: center;
		padding: 50px 50px;
		margin-bottom: 50px;
	}

	.pricing-table-style-03 .pricing-table-title{
		margin-right: 0;
	}

	/* blog */
	.blog-post {
	    margin-bottom: 50px;
	}

	/* pricing-table */
	.pricing-table-style-01{
		margin-bottom: 40px;
	}

	.pricing-table-style-02 {
	    box-shadow: none !important;
	    background: none;
	}

	.pricing-table-style-02 .pricing-table {
    	box-shadow:$box-shadow ;
        padding: 25px 20px;
        margin-bottom: 30px;
        background: $white;
	}
	.pricing-table-style-02 .pricing-table p{
		padding: 0px;
	}

	/* feature-step-list */
	.feature-step-list li .feature-step .feature-step-divider:before{
		right: -16%;
	}

	/* counter */
	.counter{
		margin-bottom: 25px;
	}

   	/* home-03 */
	.sarvice-info-box{
      padding-top:280px;
      padding-bottom: 60px;
	}

	.header.header-03 .navbar .navbar-nav {
		align-items: start;
	}

	.header.header-03 .navbar .navbar-nav li{
		display: inline-block;
		width: 100%;
	}

	/* banner-02 */
	.slider-01 .swiper-slide h1{
		font-size: 46px;
	}

	.slider-01 .swiper-slide{
		height: 600px;
	}

	.banner-overlay-left,.banner-overlay-right {
    	padding: 80px 0px;
	}

	.header.header-03 .container-fluid {
		padding: 0;
	}

    /* home-05 */

    .banner-shape {
	padding:0px 0 100px;
	margin-bottom: 0px;

    }
    .banner-form{
    	top:0px;
    	position: inherit;
    	left: 0;
    	right: 0;
    	margin-top: 30px;
    }
     .banner-shape svg{
	display: none;

    }


	/* coming-soon */
	.countdown {
	    display: inline-block;
	    text-align: left;
	    margin-right: 18px;
	    padding-right: 18px;
    }

    .space-pt.bg-overlay-right,
    .space-pt.bg-overlay-left{
    	padding-top: 0;
    }



}

@media only screen and (min-width:768px) and (max-width:991px) {
	.container {
		max-width: 96%;
	}
}

@media (max-width:767px) {

	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 28px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 17px;
	}

	.space-ptb {
		padding: 50px 0;
	}

	.space-pt {
		padding-top: 50px;
	}

	.space-pb {
		padding-bottom: 50px;
	}
	.space-sm-ptb {
		padding: 30px 0;
	}

	.space-sm-pt {
		padding-top: 30px;
	}

	.space-sm-pb {
		padding-bottom:30px;
	}

	.container {
		max-width: 100%;
	}


	/* callout */
   	.callout {
	   	margin-right: 0;
	   	padding: 50px;
	   	text-align: center;
    }

    .callout .callout-botton {
		text-align: center;
		margin-right: 0;
		margin-top: 20px;
	}

	/* step */
	.feature-step-list li{
		width: 100%;
		margin-bottom: 50px;
	}

	.feature-step-list li .feature-step .feature-step-divider:before{
		display: none;
	}

	.list{
		margin-bottom: 12px;
	}

	/* home-2 */
	.feature-section .btn{
		margin-top: 40px;
	}

	.feature-info .feature-info-icon{
		height: 80px;
	}

	.team-section-title {
    	padding-top: 60px;
	}

	.team-section {
	    position: relative;
	    z-index: 9;
	    top: -137px;
	}

	.clients-white-bg {
   	 	padding: 10px 40px;
	}

	.team{
		margin-bottom: 30px;
	}

	.blog-post {
    	margin-bottom: 30px;
	}

	.owl-carousel .owl-dots{
		margin-top: 0;
		display: none;
	}

	.service-style-01{
		margin-bottom: 30px;
	}

 	/* home-03 */
	.sarvice-info-box{
		padding-bottom: 50px;
	}

	.category-section .owl-carousel .owl-dots{
		text-align: center;
	}

  	/* contact */
	.contact-form {
        margin-right:0;
        margin-bottom: 40px;
	}

    .category-section{
    	padding: 50px 0 0 0;
    }
    .category-section:before{
       display: none;
    }

 	/* gallery */
  	.my-shuffle-container .grid-item {
		width: 50%;
	}

	/* banner-02 */
	.header.default .add-listing {
   		margin-right: 50px;
    }

	/* banner-02 */
	.slider-01 .swiper-slide{
		height: 400px;
	}

	.banner-overlay-left,
	.banner-overlay-right {
    	padding: 60px 0px;
	}

	.bg-overlay-right img,
	.bg-overlay-left img {
    	margin-bottom: 40px;
	}

	.slider-01 .swiper-slide h1 {
    	font-size: 36px;
	}

	.banner-overlay-left h1,
	.banner-overlay-left .btn{
		margin-left: 50px;
	}

	.banner-overlay-right h1,
	.banner-overlay-right .btn{
		margin-right: 50px;
	}

	.swiper-button-next,
	.swiper-button-prev{
		display: none;
	}

	.banner-shap-right,.banner-shap-left{
		top: 50%;
	}

	.banner-overlay-left:before, .banner-overlay-right:before {
        width: 20%;
	}

	.slider-01 .slider-1 .btn{
		font-size: 14px;
		padding: 10px 30px;
	}

   /* banner-03 */
    .header.header-03 .navbar .add-listing .phone{
    	display: none;
    }

	.header.header-03 .navbar .add-listing{
    	flex: 0 0 220px;
	}

	.header.header-03 .navbar .navbar-brand {
    	flex: 0 0 160px;
    }

   .banner-shape h1{

     font-size: 34px;

   }

	.coming-soon .slide-gallery{
    	display: none;
    }

    .coming-soon-content{
    	padding: 150px 50px 100px;
    }

    .blog-author .author .author-detail .author-social ul li a{
    	margin-right:10px;
    }

    .blog-detail .blog-post-navigation .post-navigation .nav-link .nav-previous{
    	position: inherit;
    	margin-bottom: 30px;
    }

	.testimonial-04 {
    	padding: 70px 0px 70px 0;
	}

	.testimonial-03 .testimonial-info{
		padding: 30px;
	}

	.header-inner {
	    margin-bottom: 30px;
	}

	.header-inner .inner-banner-title {
       font-size: 20px;
       padding: 20px 30px;
    }

    .pricing-table-style-03{
      margin-bottom:40px;
    }

    /* footer */
  	.footer .footer-bottom {
		margin-top: 10px;
	}


	/* tab */
    .tab-content #choose-us {
	    margin-top: 40px;
	}
	.tab-content #question {
	    margin-top: 25px;
	}

	/* reviews */
	.commentlist .comment-content .reviews{
      display: block;
  	}
  	.commentlist .comment-content .reviews .rating{
  		margin-bottom: 10px;
  	}

  	/* Shop */

  	.cart-table .actions{
  		display:block;
  	}
  	.cart-table .actions .coupon{
  		margin-bottom: 20px;
  	}

  	.shop-sidebar .widget{
		margin-bottom: 30px;
  	}
  	.shop-sidebar .widget .widget-title{
		margin-bottom: 20px;
  	}

  	.slider-slick .slider-nav .slick-arrow{
  		display: none !important;
  	}
  
  	.slider-slick .slider-nav .slick-prev {
    	left: 0px;
	}
	.slider-slick .slider-nav .slick-next{
		right: 0;
	}

	.slick-prev{
		left: 0;
	}

	.slick-next{
		right: 0;
	}



}

@media (max-width:575px) {

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 24px;
	}


	.section-title {
		margin-bottom: 30px;
	}

	.header.header-02 .navbar .add-listing,
	.header .add-listing{
		flex: 0 0 50%;
		margin-bottom: 0px;
	}

	.header .navbar .navbar-brand,
	.header.header-03 .navbar .navbar-brand{
        flex: 0 0 130px;
	}

	.navbar-toggler {
	    height: 35px;
	    top: 18px;
	}

	/* banner */
	.banner {
	    padding: 100px 0px;
	}

	.banner h1 {
	    font-size: 36px;
	}

	.banner .lead {
	    font-size: 18px;
	}

	.header-inner {
		margin-bottom: 40px;
	}

	.header-inner .inner-banner-title{
		width: 95%;
	}

	.slider-01 .slider-1 .btn{
		padding: 8px 20px;
	}

	.frame-content h1 {
	    font-size: 48px;
	    line-height: 48px;
	}

	/* testimonial */
	.testimonial .testimonial-info {
	    padding: 110px 40px 40px;
	}

	.testimonial .testimonial-info .quotes{
		left:20px;
	}


	.testimonial-quote i{
		font-size: 60px;
	}
	.testimonial .testimonial-info .testimonial-quote {
	    top: -50px;
	    left: 30px;
	}

	.testimonial .testimonial-info .testimonial-content{
		margin-bottom: 30px;
	}

	.testimonial-02 .testimonial-info{
		padding: 50px 40px;
	}

	.testimonial-02 .testimonial-info .testimonial-author {
	    margin-bottom: -130px;
	}

	.testimonial-02 .testimonial-info .testimonial-quote,
	.testimonial-03 .testimonial-info .testimonial-quote,
	.testimonial-04 .testimonial-info .testimonial-quote{
		top: -50px;
	}

	.testimonial-02 .testimonial-info .testimonial-content{
		margin-bottom: 30px;
	}

	.owl-carousel .owl-nav .owl-prev,
	.owl-carousel .owl-nav .owl-next{
		display: none;

	}
	.testimonial {
	    padding: 70px 30px 30px;
	}

	/* accordion */

	.accordion .accordion-icon.card-header button{
		font-size: 18px;
		padding: 15px 20px 15px 70px;
	}

	.accordion .card .card-header button:before {
	    left: 30px;
	    width: 30px;
	    height: 30px;
	    line-height: 30px;
	}

	.accordion .card .card-body {
	 	padding: 10px 30px 0px 70px;
	}

	/* service-style-02 */
 	.service-style-02 {
	 	display: inline-block;
	 	padding: 20px;
	 	text-align: center;
	 	margin:0px 0px 20px 0px;
	}

	.service-style-02 .service-image{
	 	margin: 0 0 20px;
	}

	.service-style-02 .service-content {
	   margin-left: 0;
	}

 	.space-px {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	/* tabs */
	.nav-tabs .nav-item .nav-link {
	    border: none;
	    font-size: 14px;
	    padding: 15px 15px;
	}

	.feature-info.left-icon {
	    padding: 0px;
	    margin-bottom: 30px;
	}

	.our-clients h4{
		margin-bottom: 20px;
	}

	.bg-overlay-left:before,
	.bg-overlay-right:before{
		height: 250px;
	}

	/* home-2 */

	.team-section-title {
    	padding-top: 40px;
	}

	.blog-post {
    	margin-bottom: 30px;
	}

 	/* home-03 */
	.sarvice-info-box{
		padding: 40px 0;
	}

	.video-section{
		margin-bottom: 40px;
	}

	.video-section .about-video .video span{
		font-size:60px;
    }

	.error-404-images {
	    margin-bottom: 30px;
	}

	/* banner02 */
	.banner-overlay-right h1,
	.banner-overlay-right .btn{
		margin-right: 20px;
		font-size: 12px;
	}

	.banner-overlay-left h1,
	.banner-overlay-left .btn{
		margin-left: 20px;
		font-size: 12px;
	}

	.slider-01 .swiper-slide h1 {
	    font-size: 22px;
	    margin-bottom: 20px;
	}

	.banner-overlay-left, .banner-overlay-right {
	    padding: 40px 0px;
	}

	.banner-overlay-left:before,
	.banner-overlay-right:before {
    	width: 25%;
	}

	.header .container-fluid{
		padding: 0 30px;
	}

	.countdown span{
		font-size:28px;
	}

	.countdown{
	    margin-right: 10px;
        padding-right: 10px;
	}

	.countdown p{
		font-size: 14px;
	}
	/* home-04 */
	.btn-app {
    	display: inline-flex;
	}

   /* home-04 */
	.banner-shape {
	  padding:0px 0 50px;

    }

	.pagination .page-item {
    	margin: 0 8px;
	}
	.pagination .page-item .page-link {
    	padding: 8px 10px;
	}

	/* blog-detail */
	.blog-detail .comments-01{
		display: block;
	}
	.blog-detail .comments-01 .comment-author img {
	    margin-bottom: 30px;
	}
	.blog-detail .comments-01 .comment-content{
		margin-bottom: 30px;
		margin-left: 0;
	}

	.blog-detail .comments-02{
		padding-left: 50px;
		display: block;
	}
	.blog-detail .comments-02 .comment-author img {
	    margin-bottom: 30px;
	}
	.blog-detail .comments-02 .comment-content{
		margin-bottom: 30px;
		margin-left: 0;
	}
}

@media (max-width:479px) {

	/* banner */
	.banner h1 {
	    font-size: 30px;
	}

	.frame-content h1 {
	    font-size: 30px;
	    line-height: 30px;
	}

	/* header-inner */
	.header.header-03 .navbar .add-listing {
	    flex: 0 0 50%;
	}

	/* testimonial */
	.avatar.avatar-lg {
	    width: 80px;
	    height: 80px;
	}

	.testimonial {
	    padding: 50px 30px 30px;
	}

	.testimonial .testimonial-info {
	    padding: 50px 30px 30px;
	}

	.testimonial .testimonial-info .quotes{
		left:0px;
	}
	.testimonial .testimonial-info .testimonial-quote {
    	left: 10px;
	}

	.testimonial .testimonial-info .testimonial-content{
		font-size: 18px;
	}

	.testimonial-02 .testimonial-info{
		padding: 30px 20px;
	}

	.testimonial-02 .testimonial-info .testimonial-content{
		font-size: 16px;
	}

	.testimonial-03 .testimonial-info .testimonial-author{
		bottom:-100px;
	}

	.testimonial-04{
		margin-right: 0;
	}
	.testimonial-04 .testimonial-info .testimonial-quote{
		left: 20%;
	}

	.owl-carousel .owl-nav i {
	    width: 50px;
	    height: 100px;
	    line-height: 100px;
	}

	/* callout */
	.callout .callout-botton .btn {
	    padding: 20px 16px;
	}

	/* service */
	.service-style-01 {
	    margin-bottom: 30px;
	}

	.error-404 h1{
		font-size: 48px;
	}

	/* gallery */
	.my-shuffle-container .grid-item {
		width: 100%;
	}

	/* feature-info */
	.feature-info .feature-info-icon i{
		font-size: 48px;
	}

	.feature-info .feature-info-content .feature-info-title {
	    margin-bottom: 10px;
	}

	/* banner-02 */
	.slider-01 .swiper-slide{
		height: 350px;
	}

	/* sitter-account */
	.account-info .btn{
		padding: 10px 15px;
	}

	/* sitter-account */
	.sitter-account .account-info .account-separator:before,
	.sitter-account .account-info .account-separator:after{
		width: 90px;
	}

	.coming-soon-content{
		padding: 120px 30px 70px;
	}

	.header .container-fluid{
		padding:0px 15px;
	}
	.navbar .navbar-brand{
		padding:15px 0px;
	}

   	.social-icon ul li a{
		height: 30px;
		width: 30px;
		line-height: 30px;
		margin-right:10px;
   	}

   .social-icon ul li a i {
	    font-size: 14px;
	}

	.header-transparent .navbar-brand{
		margin-right: 10px;
		padding: 10px 0px;
	}

	.our-clients .item{
		padding: 0 60px;
	}

	/* input-group */
	.product-detail .input-group{
    	width: 70px;
	}
	/* nav-tabs */
	.nav-tabs {
		display: block;
	}
	.nav-tabs.nav-tabs-02 .nav-item,
	.nav-tabs .nav-item{
		margin: 0 0 10px 0;
	}


	/* cart */

  	.cart-table .actions .coupon .input-text{
  		width: 100%;
  		margin-bottom: 20px;
  	}
	.pagination {
		padding: 5px;
	}
  	.pagination .page-item {
    	margin: 0 3px;
	}


}

@media (max-width:391px) {

	.team .team-detail {
	    padding: 20px 20px 20px;
	}

	.owl-carousel .owl-nav i {
	    width: 40px;
	    height: 80px;
	    line-height: 80px;
	    font-size: 20px;
	}

	.feature-info.left-icon .feature-info-icon {
	    width: 250px;
	}

	.feature-info .feature-info-icon i{
		font-size: 48px;
	}

	/* banner-02 */
	.slider-01 .swiper-slide{
		height: 300px;
	}

	/* header-02 */
	.header .navbar .navbar-brand,
	.header.header-03 .navbar .navbar-brand{
        flex: 0 0 100px;
	}

	.header.header-02 .navbar {
       padding: 0px 15px;
	}

	.header .navbar .add-listing .btn.btn-sm {
	  	padding: 6px 15px;
	    font-size: 14px;
	}

	.header .add-listing {
    	margin-right: 30px !important;
    }

    .our-clients .item {
	    padding: 0 30px;
	}

}
